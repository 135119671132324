<template>
  <v-container fluid class="home">
    <notification-popup
      :dialog="notifSuccess"
      :contentMsg="$t('notifTicketSuccessAdd')"
      :headerMsg="$t('Yeay')"
      :nameBtnNo="$t('ViewAnotherTicket')"
      :nameBtnYes="$t('ViewCart&CheckOut')"
      :imgSource="'success-ticket.svg'"
      colorIcon="success"
      typeModal="dialog"
      Icon="mdi-check-circle"
      :outlinedBorderBtnNo="false"
      :onHandlerYes="buttonOke"
      :onHandlerNo="buttonNo"
    />

    <notification-popup
      :dialog="notifFailed"
      :contentMsg="$t('notifTicketFailedAdd')"
      :headerMsg="$t('Failed')"
      :nameBtnNo="$t('labelNo')"
      :nameBtnYes="$t('labelYes')"
      colorIcon="danger"
      typeModal="transaction"
      Icon="mdi-close-circle"
      :onHandlerYes="buttonOkeFailed"
    />

    <RouteInformation
      :routeTitle="itemRoute.fare_name"
      :keyItem="itemRoute.fare_id"
      :titleheadlineCard="itemRoute.orig_loc"
      :origin="itemRoute.original_loc"
      :ToOrig="itemRoute.route_loc"
      :destination="itemRoute.destination_loc"
      :ToDest="itemRoute.route_loc"
      :dialog="routeInformationDialog"
      :orig="itemRoute.orig_loc"
      :dest="itemRoute.dest_loc"
    />
    <ListofTicket
      :item="item"
      :clickRouteInformation="clickRouteInformation"
      :togglebuy="togglebuy"
    />

    <v-footer fixed app width="auto" color="white" elevation="3">
      <v-container>
        <v-row class="pa-0 ma-0">
          <v-col cols="12" align-self="center">
            <v-btn
              rounded
              block
              class="text-capitalize"
              color="primary"
              :disabled="buttonDisabled"
              @click="addToCart(item)"
            >
              {{ $t("AddToCart") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </v-container>
</template>

<script>
import store from "@/store";
import NotificationPopup from "@/components/NotificationPopup.vue";
// import CardBuyTicket from "@/components/CardBuyTicket.vue";
import RouteInformation from "@/components/RouteInformation.vue";
import ListofTicket from "@/components/ListofMultitrips.vue";
export default {
  name: "FlateFare",
  components: { RouteInformation, NotificationPopup, ListofTicket },
  data() {
    return {
      searchticket: "",
      routeInformationDialog: false,
      notifSuccess: false,
      notifFailed: false,
      selected: [],
      item: [],
      page: 1,
      limit: 10,
      bottom: false,
      isEnd: false,      
      buttonDisabled: false,
      customerID: store.state.auth.userData.customerID,
    };
  },
  computed: {
    itemRoute() {
      return this.$store.state.moduleUi.routeInformation;
    },
  },
  watch: {
    bottom(bottom) {
      if (bottom & !this.isEnd) {
        this.addData();
      }
    },
    itemRoute() {
      if (this.itemRoute == "") {
        this.routeInformationDialog = false;
      } else {
        this.routeInformationDialog = true;
      }
    },
    item: {
      handler: function () {
        if (this.item.filter((item) => item.is_buy == true).length > 0) {
          this.buttonDisabled = false;
        } else {
          this.buttonDisabled = true;
        }
      },
      deep: true,
    },
    buttonDisabled() {
      let data = this.item.filter((item) => item.is_buy == true);
      if (data.length < 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    bottomVisible() {
      const scrollY = window.scrollY;
      const visible = document.documentElement.clientHeight;
      const pageHeight = document.documentElement.scrollHeight;
      const bottomOfPage = visible + scrollY >= pageHeight;
      return bottomOfPage || pageHeight < visible;
    },
    refreshCart() {
      const payload = {
        customerID: this.customerID,
      };
      this.$store
        .dispatch("purchaseticket/getQtyCart", payload)
        .catch((err) => {
          console.log(err);
        });
      this.item = this.item.map((el) => {
        var o = Object.assign({}, el);
        o.is_buy = false;
        return o;
      });
      console.log(this.item);
    },
    togglebuy(value, index) {
      this.item[index].is_buy = value;
    },
    clickRouteInformation() {},
    addToCart(items) {
      this.selected = [];
      this.selected = items.filter((items) => items.is_buy == true);

      const payload = {
        customerID: this.customerID,
        item: this.selected,
      };
      this.$store
        .dispatch("purchaseticket/addtocart", payload)
        .then((response) => {
          this.notifSuccess = true;
          this.item.cart_qty = 0;
          if (response.status) console.log("success");
        })
        .catch((err) => {
          this.notifFailed = true;
          console.log(err);
        });
    },
    buttonOke() {
      this.notifSuccess = false;
      this.item.cart_qty = 0;
      if (this.notifSuccess == false) this.$router.push({ name: "Cart" });
    },
    buttonNo() {
      this.notifSuccess = false;
      this.refreshCart();
      // if (this.notifSuccess == false) this.$router.push({ name: "Cart" });
    },
    buttonOkeFailed() {
      this.notifFailed = false;
      // if (this.notifFailed == false) this.$router.push({ name: "PaymentMethodAdd" });
    },
    toCartPage() {
      this.$router.push({ name: "Cart" });
    },
      addData() {
      var payload = {
        page: this.page,
        limit: this.limit,
      };
      this.$store
        .dispatch("purchaseticket/getStudentFare", payload)
        .then((response) => {
          for (let data of response.data) {
            this.item.push(data);
          }
          this.page = this.page + 1;
          this.item = this.item.map((el) => {
            var o = Object.assign({}, el);
            o.is_buy = o.is_buy || false;
            return o;
          });
          if (response.page == response.lastPage) {
            this.isEnd = true;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },

  mounted() {
    window.addEventListener("scroll", () => {
      this.bottom = this.bottomVisible();
    });
    this.addData();
  },
  // mounted() {
  //   this.$store
  //     .dispatch("purchaseticket/getStudentFare")
  //     .then((response) => {
  //       this.item = [];
  //       for (let data of response.data) {
  //         this.item.push(data);
  //       }
  //       this.item = this.item.map((el) => {
  //         var o = Object.assign({}, el);
  //         o.is_buy = false;
  //         return o;
  //       });
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // },
};
</script>
<style lang="scss" scoped>
/* If the screen size is 601px or more, set the font-size of <div> to 80px */
@media only screen and (min-width: 601px) {
  .home {
    background: #f5fff7;
  }
  .class-search-ticket {
    background: #f5fff7;
    margin-bottom: -10px;
  }
  .grid {
    display: grid;
  }
  .search-ticket {
    width: 100%;
    padding-left: 30%;
  }

  .footer {
    margin-top: 5%;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 70px;
    text-align: center;
    background: #ffffff;
    mix-blend-mode: normal;
    /* Field */
    box-shadow: 0px 1px 10px 0px;
  }
  .text-center {
    align-content: center;
  }
}

/* If the screen max size is 600px, set the font-size of <div> to 80px */
@media only screen and (max-width: 600px) {
  .home {
    background: #f5fff7;
  }
  .class-search-ticket {
    margin: 10px;
    background: #fdfdfd;
    margin-bottom: -10% !important;
  }
  .grid {
    display: grid;
  }

  .search-ticket {
    width: 100%;
  }

  .footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    text-align: center;
    background: #ffffff;
    mix-blend-mode: normal;
    /* Field */

    box-shadow: 0px 1px 10px 0px;
  }
  .text-center {
    align-content: center;
  }
}
</style>